import { chunk, difference, flatten, takeRight } from "lodash-es";
import { useCallback, useReducer, useState } from "react";
import { RelayPaginationProp } from "react-relay";

/*
This file manages the state of a paginated table with select row, select all and filter actions
 */

function loadDataAction(data: any, defaultFetchCount: number) {
  const pages: any = chunk(data?.edges, defaultFetchCount);
  return {
    type: "LOAD_DATA",
    pages,
    filteredCount: data?.totalCount,
  } as const;
}

function setPageAction(page: number) {
  return {
    type: "SET_PAGE",
    page,
  } as const;
}

function selectAllAction(ids: string[]) {
  return {
    type: "SELECT_ALL",
    ids,
  } as const;
}

function selectNumberAction(ids: string[], numberToSelect: number) {
  return {
    type: "SELECT_NUMBER",
    ids,
    numberToSelect,
  } as const;
}

function selectNoneAction() {
  return {
    type: "SELECT_NONE",
  } as const;
}

function selectRowsAction(ids: string[]) {
  return {
    type: "SELECT_ROWS",
    ids: ids,
  } as const;
}

type State = {
  selectAllChecked: boolean;
  page: number;
  pages: any[];
  filteredCount: number;
  selectedIds: string[];
  countToSelect: number;
  defaultFetchCount: number;
};

type Action = ReturnType<
  | typeof loadDataAction
  | typeof setPageAction
  | typeof selectAllAction
  | typeof selectNumberAction
  | typeof selectNoneAction
  | typeof selectRowsAction
>;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "LOAD_DATA":
      if (state.selectAllChecked) {
        let addedIds = takeRight(flatten(action.pages), state.defaultFetchCount).map((o: any) => o.node.id);
        const numOfDownloadedRows = flatten(state.pages).length + state.defaultFetchCount;
        // Check if we have downloaded more rows than user specified to select. If so, we only select up to the
        // specified number (countToSelect)
        if (state.countToSelect > 0 && numOfDownloadedRows > state.countToSelect) {
          if (numOfDownloadedRows < state.countToSelect + state.defaultFetchCount) {
            addedIds = addedIds.slice(0, state.countToSelect - (numOfDownloadedRows - state.defaultFetchCount));
          } else {
            addedIds = [];
          }
        }

        return {
          ...state,
          pages: action.pages,
          filteredCount: action.filteredCount,
          selectedIds: Array.from(new Set([...state.selectedIds, ...addedIds])),
        };
      } else {
        return { ...state, pages: action.pages, filteredCount: action.filteredCount };
      }
    case "SET_PAGE":
      return { ...state, page: action.page };
    case "SELECT_ALL":
      return { ...state, selectAllChecked: true, selectedIds: action.ids };
    case "SELECT_NUMBER":
      return {
        ...state,
        selectAllChecked: true,
        selectedIds: action.ids,
        countToSelect: action.numberToSelect,
      };
    case "SELECT_NONE":
      return { ...state, selectAllChecked: false, selectedIds: [], countToSelect: 0 };
    case "SELECT_ROWS":
      return {
        ...state,
        selectedIds: action.ids,
        selectAllChecked: action.ids.length === 0 ? false : state.selectAllChecked,
      };
    default:
      return state;
  }
}

function useTableSelect({ relay, defaultFetchCount }: { relay: RelayPaginationProp; defaultFetchCount: number }) {
  const [state, dispatch] = useReducer(reducer, {
    selectAllChecked: false,
    page: 1,
    pages: [],
    filteredCount: 0,
    selectedIds: [],
    countToSelect: 0,
    defaultFetchCount: defaultFetchCount || 10,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const load = useCallback(
    (data: any) => {
      dispatch(loadDataAction(data, defaultFetchCount));
    },
    [defaultFetchCount]
  );
  const setPage = (page: number) => dispatch(setPageAction(page));
  const selectRows = (ids: string[]) => dispatch(selectRowsAction(ids));
  const selectNone = () => dispatch(selectNoneAction());

  const changePage = (pageTo: number) => {
    const shouldFetch = relay.hasMore() && state.pages.length + 1 === pageTo;

    if (shouldFetch) {
      setLoading(true);
      relay.loadMore(defaultFetchCount, e => {
        if (e) console.error(e);
        setPage(pageTo);
        setLoading(false);
      });
    } else {
      setPage(pageTo);
    }
  };

  const toggleSelectAll = (checked: boolean, numberToSelect?: number) => {
    if (checked) {
      const ids: string[] = [];
      let selectedCount = 0;
      // Select all panelists in the table up to the specified numberToSelect (if supplied)
      state.pages.forEach((page: any) => {
        page.forEach((edge: any) => {
          if (!edge.node.recruitedToStudy && numberToSelect ? selectedCount < numberToSelect : true) {
            ids.push(edge.node.id);
            selectedCount++;
          }
        });
      });
      if (numberToSelect) {
        dispatch(selectNumberAction(ids, numberToSelect));
      } else {
        dispatch(selectAllAction(ids));
      }
    } else {
      dispatch(selectNoneAction());
    }
  };

  const numOfDownloadedRows = flatten(state.pages).length;
  let deselectedCount = 0;
  if (numOfDownloadedRows > state.countToSelect) {
    deselectedCount = state.countToSelect - state.selectedIds.length;
  } else {
    deselectedCount = numOfDownloadedRows - state.selectedIds.length;
  }

  return {
    ...state,
    pagedData: state.pages[state.page - 1],
    selectedCount:
      state.selectAllChecked && state.countToSelect
        ? state.countToSelect - deselectedCount
        : state.selectAllChecked
        ? state.filteredCount - (numOfDownloadedRows - state.selectedIds.length)
        : state.selectedIds.length,
    deselectedIds: difference(
      flatten(state.pages).map((o: any) => o.node.id),
      state.selectedIds
    ),
    loading,
    setLoading,
    selectRows,
    changePage,
    setPage,
    toggleSelectAll,
    load,
    selectNone,
  };
}

export default useTableSelect;
